.hooks__list {
  width: 100%;

  tr {
    &.disabled {
      opacity: 0.4;

      td {
        cursor: default;
        background: transparent;
        border-color: transparent;
      }
    }

    &.cancel td {
      background: $m-secondary--fade-60;
    }

    &.join:hover td {
      background: $m-accent--fade-50;
      color: #fff;
    }

    &:not(.disabled):hover ::before {
      opacity: 1;
    }

    &.variants td {
      text-align: center;
      padding: 3px 0;
      background: none;
      text-transform: uppercase;
      letter-spacing: 3px;
      cursor: default;
    }
  }

  th {
    @extend %roboto;

    padding: 1em 0.7em;

    &.sortable {
      cursor: pointer;
    }

    &.sortable:hover,
    &.sort {
      font-weight: normal;
    }

    &.sort .is:before {
      opacity: 0.7;
      margin-inline-end: 3px;
      content: $licon-DownTriangle;
    }

    &.player {
      width: 110px;
    }

    @media (max-width: at-most($xx-small)) {
      padding: 1em 0.4em;

      &:nth-child(2) {
        max-width: 13vw;
        direction: rtl;
        text-align: left;
      }
    }
  }

  td {
    @extend %nowrap-hidden;

    padding: 0.5em 0.7em;
    border-bottom: $border;
    cursor: pointer;
    background: $m-bg--fade-50;

    &:first-child {
      padding-inline-start: 2em;
    }
    &:last-child ::before {
      margin-inline-end: 8px;
      line-height: 0.9;
      font-size: 1.6em;
    }

    ::before {
      opacity: 0.6;
    }

    @media (max-width: at-most($xx-small)) {
      padding: 0.5em 0.4em;

      // player name
      &:first-child {
        @include ellipsis;
        max-width: 25vw;
      }
    }
  }

  tbody.stepping {
    opacity: 0.7;
  }
}

.lseeks .create {
  margin-top: 20px;
  text-align: center;
}
