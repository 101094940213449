.lobby {
  &__table {
    @extend %flex-column;
    @include prevent-select;

    position: relative;
  }

  &__start {
    @extend %flex-column;

    justify-content: stretch;
    flex: 1 1 auto;
    gap: 0.5em;

    button {
      font-size: 1.1em;
      text-align: center;
      padding: 0.5em;

      &.disabled {
        opacity: 0.2;
      }
    }
  }

  &__counters {
    @extend %flex-between-nowrap, %page-text;
    margin-top: 1rem;
    display: none;

    a {
      @extend %nowrap-ellipsis;
      color: $c-font-page;

      @include transition(color);

      &:hover {
        color: $c-link;
      }
    }
  }

  .lobby__tournaments-simuls .timeago {
    @media (max-width: at-most($x-small)) {
      display: none;
    }
  }
}
