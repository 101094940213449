.lobby__timeline {
  @extend %page-text;
  flex: 1 1 auto;
  margin-top: 1em;
  margin-inline-start: $block-gap;

  @include hoverflow;

  .entry {
    @extend %roboto;

    a {
      @extend %base-font, %page-font;
      font-weight: normal;
    }

    &:hover a {
      color: $c-link;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: linear-gradient(to start, $c-border 20%, $c-bg-page);
      margin: 0.7em 0;
      @include if-rtl {
        background: linear-gradient(to end, $c-border 20%, $c-bg-page);
      }
    }

    .line:not(.patron) {
      display: none;
    }
  }

  .more {
    @extend %lobby__side__more;
  }
}
