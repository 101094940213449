.lobby {
  &__box {
    @extend %flex-column, %box-neat-force;

    &__top {
      @extend %metal, %flex-between;

      flex: 0 0 auto;
      padding: 0.3em 0.5em;

      .more {
        color: $c-font-dim;

        &:hover {
          color: $c-link;
        }
      }

      .title {
        @extend %base-font;

        font-size: 1em;
        font-weight: bold;
        color: $c-font-dim;
        padding: 0.3em;
      }
    }

    &__content {
      background: $c-bg-box;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .user-link {
      font-weight: bold;
      color: $c-font-dim;
    }

    table {
      width: 100%;
      height: 100%;
    }

    td {
      @extend %nowrap-ellipsis;

      padding: 0.5em 0.4em;
      border-top: $border;
      max-width: 21ch;
      @media (max-width: at-most($x-small)) {
        max-width: 18ch;
      }

      &.name a {
        font-weight: bold;
        color: $c-font-dim;

        @include transition;

        &:hover {
          color: $c-link;
        }
      }
    }

    tr:nth-child(even) {
      background: $c-bg-zebra;
    }
  }

  &__blog .lobby__box__top {
    border-bottom: $border;
  }

  .progress-td {
    padding: 0.3em 0.4em;
  }
  .progress {
    border-radius: 2em;
    display: block;
    position: relative;
    width: 30ch;
    height: 1.25em;

    background: $m-bg-page--fade-40;
    @include if-light {
      background: $m-shade--fade-40;
    }
    @include if-transp {
      background: $m-bg--fade-40;
    }
    overflow: hidden;
    @media (max-width: at-most($x-small)) {
      max-width: 23ch;
      margin: auto;
    }
    @media (max-width: at-most($xx-small)) {
      max-width: 16ch;
    }
  }
  .progress__bar {
    border-radius: 2em;
    display: block;
    height: 100%;
    background: $m-primary_bg-page__mix-20--fade-70;
  }
  .progress__text {
    position: absolute;
    color: $c-font;
    font-size: 0.9em;
    top: 0;
    width: 100%;
    @include inline-start(1.5ch);
    z-index: 3;
  }
}
