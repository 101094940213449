.now-playing {
  .meta {
    @extend %flex-column;

    align-items: center;
  }

  .local {
    position: relative;
    .mini-board-wrapper {
      padding: 12px;
    }
    img {
      pointer-events: none;
      position: absolute;
      top: 6px;
      left: 6px;
      width: 72px;
      height: 72px;
      z-index: $z-above-pieces-9;
      border-radius: 6px;
      border: 1px solid #7779;

      &.white {
        background-color: $c-paper;
      }
      &.black {
        background-color: $c-dark;
      }
      @include if-transp {
        backdrop-filter: blur(6px);
        //-webkit-backdrop-filter: blur(6px);

        &.white {
          background-color: $m-paper--fade-40;
        }
        &.black {
          background-color: $m-dark--fade-40;
        }
      }
    }
    button {
      position: absolute;
      background: center / contain url('../flair/img/symbols.cancel.webp') $c-bg-page;
      border-radius: 100%;
      border: 2px solid $c-bg-page;
      top: 4px;
      right: 4px;
      z-index: $z-above-pieces-9;
      width: 24px;
      height: 24px;
      @include mq-is-col1 {
        top: 2px;
        right: 2px;
        width: 32px; // fat fingers
        height: 32px;
      }
      &:hover {
        filter: brightness(140%);
      }
    }
  }
}
